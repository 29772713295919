


















import { Component, Prop, Vue } from 'vue-property-decorator';
import { IReadyOrders } from '@/utils/models';

@Component
export default class OrdersReady extends Vue {
  @Prop() public orders!: IReadyOrders;
}
