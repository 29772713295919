









import { Component, Prop, Vue } from 'vue-property-decorator';
import CurrentDate from '@/components/CurrentDate.vue';
import { IShift, ShiftType } from '@/utils/models';

@Component({
  components: { CurrentDate },
})
export default class Header extends Vue {
  @Prop() public restaurantName!: string;

  @Prop() public shift!: IShift;

  shiftType = ShiftType
}
