import { render, staticRenderFns } from "./Reviews.vue?vue&type=template&id=e8b77498&scoped=true&lang=pug&"
import script from "./Reviews.vue?vue&type=script&lang=ts&"
export * from "./Reviews.vue?vue&type=script&lang=ts&"
import style0 from "./Reviews.vue?vue&type=style&index=0&id=e8b77498&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8b77498",
  null
  
)

export default component.exports