








import { Component, Prop, Vue } from 'vue-property-decorator';
import { Reminder } from '@/utils/models';

@Component
export default class VideoMessage extends Vue {
  @Prop() public reminder!: Reminder;

  videoPath: string | null = null;

  mounted() {
    // const urlArray = this.reminder.video_url.split('/');
    // const fileName = urlArray[urlArray.length - 1];

    // eslint-disable-next-line global-require,import/no-dynamic-require
    // this.videoPath = require(`../assets/videos/${fileName}`);
    this.videoPath = this.reminder.video_url;

    setTimeout(() => {
      (this.$refs.video as any).play();
    }, 0);
  }
}
