<template lang="pug">
.ORDERS
  template
    audio#audioOrder(:src="orderSong")
    Order#processing(
      :orders="orders.processing_orders",
      :img="imgForProcessingOrders"
    )
    Order#processed(:orders="orders.processed_orders")
</template>

<script>

import { Component, Vue } from "vue-property-decorator";
import Order from "./Components/Order.vue";
import { Socket } from "vue-socket.io-extended";

@Component({
  components: {
    Order
  },
})

export default class Dashboard extends Vue {
  orders = {
    processing_orders: [],
    processed_orders: []
  };
  imgForProcessingOrders = '';
  orderSong = 'https://static.mlkitchen.ai/dashboard/sound/kd_courier_order_ready.mp3'
  isInit = false;

  mounted () {
    this.$socket.client.nsp = '/courier_dashboard';

    window.addEventListener(
      "click",
      () => {
        this.isInit = true;
      },
      { once: true }
    );
  }

  setOrder (data) {
    let that = this;
    function deleteOrder (name) {
      let index = that.orders[name].findIndex(el => el.id === data.id);
      if (index > -1) {
        that.orders[name].splice(index, 1);
      }
    }

    function checkOrder (name, status) {
      let index = that.orders[name].findIndex(el => el.id === data.id);
      if (index === -1) {
        if (status === 'PROCESSED' && that.isInit) {
          document.querySelector('#audioOrder').play();
        }
        that.orders[name].push(data);
      }
    }

    if (data.processing_status === "PROCESSING") {
      console.log(1, data.external_id, data.processing_status, new Date().getHours(), new Date().getMinutes())
      deleteOrder('processed_orders')
      checkOrder('processing_orders', 'PROCESSING', data)
    } else if (data.processing_status === "PROCESSED") {
      deleteOrder('processing_orders')
      checkOrder('processed_orders', 'PROCESSED', data)
      let time = 600000; // 10 мин
      if (data.closed_date !== null) {
        time = new Date(data.closed_date).getTime() + 600000 - new Date().getTime();
      }
      console.log(2, data.external_id, data.processing_status, new Date().getHours(), new Date().getMinutes(), time)
      setTimeout(() => {
        console.log(222, data.external_id, data.processing_status, new Date().getHours(), new Date().getMinutes(), time)
        deleteOrder('processing_orders')
        deleteOrder('processed_orders')
      }, time)
    } else {
      console.log(3, data.external_id, data.processing_status, new Date().getHours(), new Date().getMinutes())
      deleteOrder('processing_orders')
      deleteOrder('processed_orders')
    }
  }

  @Socket("courier_order_init")
  courierOrderInit (readyOrders) {
    this.orders.processing_orders = [];
    this.orders.processed_orders = [];

    readyOrders.processing_orders.forEach(el => {
      this.setOrder(el)
    });
    readyOrders.processed_orders.forEach(el => {
      this.setOrder(el)
    });
  }

  @Socket("courier_order")
  courierOrder (order) {
    this.setOrder(order)
  }

  @Socket("advertisement_event")
  advertisementEvent (data) {
    this.imgForProcessingOrders = data.image_url
  }
}

</script>
<style lang="scss" scoped>
.ORDERS {
  display: flex;
  justify-content: space-between;
  height: 100%;
  .notification {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid gray;
    width: 500px;
    height: 200px;
    border-radius: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #ffffff;

    .title {
      text-align: center;
      font-size: 30px;
    }
    .buttons {
      margin-top: 20px;
      button {
        border: 1px solid gray;
        width: 150px;
        height: 80px;
        font-size: 35px;
        margin: 10px 30px;
        border-radius: 20px;
        cursor: pointer;
        outline: none;
      }
      .yes:hover {
        background: rgb(169, 236, 169);
      }
      .no:hover {
        background: rgb(214, 154, 154);
      }
    }
  }
}
</style>


