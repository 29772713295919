import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Dashboard from '@/views/Dashboard.vue';
import Orders from '@/views/Orders/Orders.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: 'kitchen/1'
  },
  {
    path: '/kitchen/:id',
    component: Dashboard,
    children: [
      {
        path: 'orders',
        component: Orders
      }
    ]
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
