











import { Component, Prop, Vue } from 'vue-property-decorator';
import { ReviewMessage, REVIEWS_TYPES } from '@/utils/models';
import { declOfNum } from '@/utils/common';

@Component
export default class ReviewItem extends Vue {
  @Prop() public review!: ReviewMessage;

  types = REVIEWS_TYPES;

  get orders() {
    return this.review.order && this.review.order.items && this.review.order.items.length ? this.review.order.items : [];
  }

  get visibleOrders() {
    const count = this.orders.length;
    // eslint-disable-next-line no-nested-ternary
    return count === 4 ? this.orders.slice(0, 4) : count > 4 ? this.orders.slice(0, 3) : this.orders;
  }

  getDeclension(number, declensions: string[]) {
    return declOfNum(number, declensions);
  }
}
