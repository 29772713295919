export enum STATUSES {
  poor = 'POOR',
  normal = 'NORMAL',
  ok = 'OK',
  unknown = 'UNKNOWN',
}

export enum AREAS {
  kitchen = 'KITCHEN',
  assembly = 'ASSEMBLY',
  delivery = 'DELIVERY',
}

export enum PERIODS {
  day = 'DAY'
}

export const periodsLabels = {
  [PERIODS.day]: 'Дневная',
};

export const areasLabels = {
  [AREAS.kitchen]: 'Кухня',
  [AREAS.assembly]: 'Комплектация',
  [AREAS.delivery]: 'Доставка',
};

export interface StatsItem {
  kitchen_id: number | string;
  creation_date?: string;
  area?: AREAS;
  perform_status: STATUSES;
  rating_position: number;
  value: number;
  show_position: boolean;
}

export interface ATOItem {
  current_audit: StatsItem;
  previous_audit: StatsItem;
}

export interface CSRItem {
  kitchen_id: number | string;
  current_csr: StatsItem[];
  previous_csr: StatsItem[];
}

export interface InitData {
  restaurant: { id: number; name: string };
}

export interface Kitchen {
  // shift: PERIODS;
  // rating_position: number;
  // position: number;
  // show_position: boolean;
  // show_rating: boolean;

  kitchen_id: number;
  kitchen_name: string;
  creation_date: Date;
  rating: number;
  value: number;
}

export interface RevenueItem {
  current: Kitchen;
  closest_high: Kitchen;
  closest_low: Kitchen;
}

export interface OrderItem {
  id: string | number;
  name: string;
}

export enum REVIEWS_TYPES {
  positive = 'POSITIVE',
  negative = 'NEGATIVE',
}

export enum MESSAGES_TYPES {
  message = 'MESSAGE',
  task = 'TASK',
  congratulation = 'CONGRATULATION'
}

export interface IdName {
  id: number | string;
  name: string;
}

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
export interface IOrder {
  closed_date: string;
  comment: string;
  complete_before: string;
  creation_date: string;
  delivery_status: string;
  external_id: string;
  id: string;
  items: OrderItem[];
  kitchen_id: number;
  marketplace: string;
  processing_status: string;
  receipt_number: number;

}

export interface ReviewMessage {
  creation_date: string;
  id: string | number;
  kitchen_id: string | number;
  text: string;
  type: REVIEWS_TYPES;
  order: IOrder;
}

export interface ReviewsCount {
  total_negative: number;
  total_positive: number;
  total_reviews: number;
}

export interface ReviewsObject {
  reviews: ReviewMessage[];
  creation_date: string;
}

export interface NotificationMessage {
  creation_date: string;
  id: string;
  restaurant: string;
  text: string;
  type: MESSAGES_TYPES;
}

export interface NotificationItem {
  messages: NotificationMessage[];
  total: 1;
}

export interface TicketTime {
  failed_count: number;
  performing_status: STATUSES;
  threshold: number;
}

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
export interface IReadyOrderItem {
  'id': string;
  'name': string;
}

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
export interface IReadyOrder {
  'ticket_time': number;
  'cooking_start': string; // date
  'cooking_end': string; // date
  'receipt_number': number;
  'creation_date': string; // date
  'order_details': {
    'id': string;
    'receipt_number': number;
    'kitchen_id': number;
    'cost': number;
    'items': IReadyOrderItem[];
    'creation_date': string; // date
  };
}

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
export interface IReadyOrders {
  average_ticket_time: number;
  recent_orders: IReadyOrders[];
}

export interface Reminder {
  creation_date: string;
  text: string;
  video_url: string;
}

export enum ShiftType {
  DAY = 'DAY',
  NIGHT = 'NIGHT',
}

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
export interface IShift {
  shift_type: ShiftType;
}
