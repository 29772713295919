










import { Component, Prop, Vue } from 'vue-property-decorator';
import RateItem from '@/components/RateItem.vue';
import { ATOItem } from '@/utils/models';
import StatNumber from '@/components/StatNumber.vue';

@Component({
  components: { StatNumber, RateItem },
})
export default class ATO extends Vue {
  @Prop() public item!: ATOItem;
}
