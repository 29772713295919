












import { Component, Prop, Vue } from 'vue-property-decorator';
import RateItem from '@/components/RateItem.vue';
import { areasLabels, CSRItem } from '@/utils/models';
import StatNumber from '@/components/StatNumber.vue';

@Component({
  components: { StatNumber, RateItem },
})
export default class CSRStats extends Vue {
  @Prop() public item!: CSRItem;

  labels = areasLabels;
}
