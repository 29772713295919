











import { Component, Prop, Vue } from 'vue-property-decorator';
import { StatsItem, STATUSES } from '@/utils/models';

@Component
export default class StatNumber extends Vue {
  @Prop() public item!: StatsItem;

  statuses = STATUSES;
}
