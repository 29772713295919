







import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class DisconnectMessage extends Vue {
  @Prop() public oldPosition!: number;

  @Prop() public currentPosition!: number;
}
