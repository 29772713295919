<template lang="pug">
.processing(:class="{ processed: id === 'processed' }")
  .title {{ id === 'processing' ? 'Готовятся' : 'Готовы' }}
  .advertising(v-if="id === 'processing' && orders.length <= 5 && img")
    img(:src="img") 
  transition-group.orders(:ref="`orders_${id}`", name="list", tag="div")
    .order.list-item(v-for="(order, index) in orders", :key="order.id")
      .left
        .img
          img(:src="getImg(order.marketplace_id)") 
        .external {{ order.external_id }}
      .time(v-if="id === 'processing'") {{ getTime(order) }}
      .price {{ order.sum }} ₽
</template>

<script>
export default {
  name: "Order",
  props: {
    orders: {
      type: Array,
      default: () => []
    },
    id: {
      type: String,
      default: "processing"
    },
    img: {
      type: String,
      default: ""
    }
  },
  data () {
    return {
      currentTime: new Date().getTime(),
      isScrollToTop: false,
      speedScrollMS: 75,
      timerScroll: null,
      iconYandexEda: "https://static.mlkitchen.ai/dashboard/image/yandex_eda.png",
      iconDeliveryClub: "https://static.mlkitchen.ai/dashboard/image/dk.png",
      iconMnogolosos: "https://static.mlkitchen.ai/dashboard/image/ml.png",
      iconOKOLO: "https://static.mlkitchen.ai/dashboard/image/okolo.png",
      iconBroniboy: "https://static.mlkitchen.ai/dashboard/image/BB.png"
    }
  },
  mounted () {
    setInterval(() => {
      this.currentTime = new Date().getTime()
    }, 60000);
    this.scrollInterval()
  },
  updated () {
    let that = this
    setTimeout(() => {
      that.scrollInterval()
    }, 3000)
  },
  methods: {
    scrollInterval () {
      clearInterval(this.timerScroll);
      if (this.$refs[`orders_${this.id}`].$el.scrollHeight !== this.$refs[`orders_${this.id}`].$el.clientHeight) {
        this.timerScroll = setInterval(() => {
          this.scrollToElement()
        }, this.speedScrollMS)
      }
    },
    scrollToElement () {
      let el = this.$refs[`orders_${this.id}`];
      if (el && el.$el.scrollHeight !== el.$el.clientHeight) {
        if (this.isScrollToTop) {
          el.$el.scrollTop -= 1
          if (el.$el.scrollTop <= 0) this.isScrollToTop = false
        } else {
          el.$el.scrollTop += 1
          if (el.$el.scrollHeight - (el.$el.clientHeight + el.$el.scrollTop) <= 0) this.isScrollToTop = true
        }
      }
    },
    getImg (id) {
      let str = this.iconMnogolosos;
      if (id === 1) str = this.iconMnogolosos;
      if (id === 2) str = this.iconDeliveryClub;
      if (id === 3) str = this.iconYandexEda;
      if (id === 4) str = this.iconOKOLO;
      if (id === 5) str = this.iconBroniboy;
      return str
    },
    getTime (order) {
      let completeTime = new Date(order.complete_before).getTime();
      if (Math.floor((completeTime - this.currentTime) / 60000) < 1) return `Почти готово`;
      else return `~${Math.floor((completeTime - this.currentTime) / 60000)} мин`;
    }
  }
};
</script>

<style lang="scss" scoped>
.processing {
  width: 60%;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.17), 0px 7px 40px rgba(0, 0, 0, 0.07);
  margin: 20px;
  position: relative;

  .title {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 40px;
    text-align: center;
    color: #ffffff;
    background: #222222;
  }
  .advertising {
    position: absolute;
    bottom: 0px;
    right: 0;
    left: 0;
    display: flex;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .orders {
    height: calc(100% - 80px);
    overflow-y: auto;
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    bottom: 100px;
    .order {
      display: flex;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 600;
      font-size: 38px;
      text-align: center;
      padding: 0 40px;
      background: #f9f9f9;

      .left {
        display: flex;
        width: 440px;
        min-width: 440px;

        .img {
          width: 60px;
          height: 60px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 10px;
          }
        }
        .external {
          margin-left: 20px;
          font-size: 46px;
        }
      }

      .time {
        color: #a7a7a7;
        width: 20%;
        min-width: 255px;
      }
      .price {
        text-align: right;
        width: 20%;
        min-width: 190px;
      }
    }
  }
}
.processed {
  margin-left: 0;
  width: 40%;
  .title {
    background: #00bc6d;
  }

  .left {
    .external {
      color: #00bc6d;
    }
  }
}

.list-item {
  display: inline-block;
}
.list-enter-active,
.list-leave-active {
  transition: all 1s;
}
.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
