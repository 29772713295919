










import { Component, Prop, Vue } from 'vue-property-decorator';
import RateItem from '@/components/RateItem.vue';
import { MESSAGES_TYPES, NotificationMessage } from '@/utils/models';

@Component({
  components: {
    RateItem,
  },
})
export default class NotificationsAlert extends Vue {
    @Prop() public notification!: NotificationMessage;

    messagesTypes = MESSAGES_TYPES;
}
