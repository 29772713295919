










import { Component, Prop, Vue } from 'vue-property-decorator';
import RateItem from '@/components/RateItem.vue';
import ReviewItem from '@/components/ReviewItem.vue';
import { ReviewMessage } from '@/utils/models';

@Component({
  components: { ReviewItem, RateItem },
})
export default class CSRStats extends Vue {
  @Prop() public reviews!: ReviewMessage[];

  get visibleReviews(): ReviewMessage[] | null {
    return this.reviews && this.reviews.length ? this.reviews.slice(0, 3) : null;
  }
}
