













import { Component, Prop, Vue } from 'vue-property-decorator';
import RateItem from '@/components/RateItem.vue';
import { STATUSES, TicketTime } from '@/utils/models';

@Component({
  components: { RateItem },
})
export default class TimeoutOrders extends Vue {
  @Prop() public ticketTime!: TicketTime;

  statuses = STATUSES;
}
