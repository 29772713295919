





import { Vue } from "vue-property-decorator";
import { Socket } from "vue-socket.io-extended";
import { InitData, IShift } from "@/utils/models";

export default class Index extends Vue {
  shift: IShift | null = null;

  restaurantName: string | null = null;

  @Socket("init_dashboard_event")
  onInitDashboard(initData: InitData) {
    this.restaurantName = initData.restaurant.name;
  }

  @Socket("kitchen_init")
  kitchenInfoEvent(msg) {
    this.shift = msg;
  }
}
