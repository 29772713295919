






































import { Component, Prop, Vue } from 'vue-property-decorator';
import RateItem from '@/components/RateItem.vue';
import { periodsLabels, RevenueItem } from '@/utils/models';
import { divideNumberWithSpaces } from '@/utils/common';

@Component({
  components: { RateItem },
})
export default class Revenue extends Vue {
  @Prop() public revenue!: RevenueItem;

  periodsLabels = periodsLabels;

  showMessage = false;

  divideNumbers = divideNumberWithSpaces;
}
