























































import { Component, Vue } from "vue-property-decorator";
import CSRStats from "@/components/CSRStats.vue";
import ATO from "@/components/ATO.vue";
import Reviews from "@/components/Reviews.vue";
import Header from "@/components/Header.vue";

import {
  ATOItem,
  CSRItem,
  IReadyOrders,
  NotificationItem,
  NotificationMessage,
  Reminder,
  RevenueItem,
  ReviewMessage,
  REVIEWS_TYPES,
  ReviewsObject,
  TicketTime,
} from "@/utils/models";
import TimeoutOrders from "@/components/TimeoutOrders.vue";
import Notifications from "@/components/Notifications.vue";
import Revenue from "@/components/Revenue.vue";
import NotificationsAlert from "@/components/NotificationsAlert.vue";
import { Socket } from "vue-socket.io-extended";
import StartMessage from "@/components/StartMessage.vue";
import {
  messageNotification,
  negativeReviewNotification,
  positiveReviewNotification,
  revenueRatingUpNotification,
  scheduleNotification,
} from "@/utils/sounds";

import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import OrdersReady from "@/components/OrdersReady.vue";
import DisconnectMessage from "@/components/DisconnectMessage.vue";
import VideoMessage from "@/components/VideoMessage.vue";
import RatingMessage from "@/components/RatingMessage.vue";

const RATING_ALERT_TIMEOUT = 1000 * 30;
const REMINDER_TIMEOUT = 1000 * 20;
const NOTIFICATION_TIMEOUT = 10000;

@Component({
  components: {
    Header,
    RatingMessage,
    VideoMessage,
    DisconnectMessage,
    OrdersReady,
    StartMessage,
    NotificationsAlert,
    Revenue,
    Notifications,
    TimeoutOrders,
    Reviews,
    ATO,
    CSRStats,
    VueSlickCarousel,
  },
})
export default class Dashboard extends Vue {
  ato: ATOItem | null = null;

  csr: CSRItem | null = null;

  revenue: RevenueItem | null = null;

  reviews: Partial<ReviewsObject> = {
    // eslint-disable-next-line @typescript-eslint/camelcase
    reviews: [],
  };

  reminder: Reminder | null = null;

  reviewsMessages: ReviewMessage[] | null = null;

  notifications: NotificationMessage[] | null = null;

  newNotification: NotificationMessage | null = null;

  readyOrders: IReadyOrders | null = null;

  ticketTime: TicketTime | null = null;

  id: string | null = null;

  showNotificationAlert = false;

  isDisconnected = false;

  isInit = false;

  showRatingAlert = false;

  oldPosition = 0;

  currentPosition = 0;

  sliderOptions = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 30000,
    dots: false,
    draggable: false,
    fade: true,
    pauseOnHover: false,
    swipe: false,
    touchMove: false,
  };

  mounted() {
    window.addEventListener(
      "click",
      () => {
        this.isInit = true;
      },
      { once: true }
    );
  }

  @Socket()
  connect() {
    this.isDisconnected = false;
    this.id = this.$route.params.id;
    if (this.id) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      this.$socket.client.emit("connection_success", { kitchen_id: this.id });
    }
  }

  @Socket("reconnect_attempt")
  reconnectAttempt() {
    // console.log('onReconnectAttempt');
    this.$socket.client.io.opts.transports = ["polling", "websocket"];
  }

  @Socket("disconnect")
  disconnect(msg) {
    // console.log('ondisconnect', msg);
    this.isDisconnected = true;
  }

  @Socket("refresh_page_event")
  refreshPage(msg) {
    window.location.reload();
  }

  @Socket("csr_updated_event")
  csrUpdatedEvent(csr: CSRItem) {
    // console.log('oncsr_updated_event', csr);
    if (csr) {
      this.csr = csr;
    }
  }

  @Socket("audit_updated_event")
  auditUpdatedEvent(ato: ATOItem) {
    this.ato = ato;
  }

  @Socket("restaurant_stop_event_init")
  restaurantStopEventInit(msg) {
    console.log("onrestaurant_stop_event_init", msg);
  }

  @Socket("restaurant_stop_event")
  restaurantStopEvent(msg) {
    // console.log('onrestaurant_stop_event', msg);
  }

  @Socket("order_closed_init")
  orderClosedInit(readyOrders: IReadyOrders) {
    // console.log('onorder_closed_init', readyOrders);
    this.readyOrders = readyOrders;
  }

  @Socket("order_closed")
  orderClosed(readyOrders: IReadyOrders) {
    // console.log('onorder_closed', readyOrders);
    this.readyOrders = readyOrders;
  }

  @Socket("revenue")
  revenueEvent(revenue: RevenueItem) {
    // console.log('revenue -> ', revenue);
    const old = Number(this.currentPosition);
    const newPosition = Number(revenue.current?.rating);
    this.oldPosition = this.currentPosition;
    this.currentPosition = newPosition;
    if (old !== 0 && newPosition < old) {
      this.showRatingAlert = true;
      this.playSound(revenueRatingUpNotification);
      setTimeout(() => {
        this.showRatingAlert = false;
      }, RATING_ALERT_TIMEOUT);
    } else {
      this.showRatingAlert = false;
    }

    this.revenue = revenue;
  }

  @Socket("review_init")
  reviewInit(reviews: ReviewsObject) {
    // console.log('review_init', reviews);
    this.reviews = reviews;
    this.reviewsMessages = reviews.reviews;
  }

  @Socket("review")
  review(reviewMessage: ReviewMessage) {
    if (reviewMessage.type === REVIEWS_TYPES.negative) {
      this.playSound(negativeReviewNotification);
    }
    if (reviewMessage.type === REVIEWS_TYPES.positive) {
      this.playSound(positiveReviewNotification);
    }
    this.handleNewReview(reviewMessage);
  }

  @Socket("reminder_event")
  reminderEvent(reminder: Reminder) {
    // console.log('onreminder_event', reminder);
    const sameReminder = this.reminder && this.reminder.text === reminder.text;
    if (!sameReminder) {
      this.reminder = reminder;
      this.playSound(messageNotification);
      setTimeout(() => {
        this.reminder = null;
      }, REMINDER_TIMEOUT);
    }
  }

  @Socket("notification_init")
  notificationInit(notificationItem: NotificationItem) {
    // console.log('onnotification_init', notificationItem);
    this.notifications = notificationItem.messages as NotificationMessage[];
  }

  @Socket("notification")
  notificationEvent(msg: NotificationMessage) {
    // console.log('onnotification', msg);
    this.playSound(messageNotification);
    this.handleNewNotification(msg);
  }

  @Socket("server_error")
  onServerError(msg) {
    // console.log('server_error -> ', msg);
  }

  handleNewNotification(notification: NotificationMessage) {
    this.newNotification = notification;
    this.showNotificationAlert = true;
    this.playSound(scheduleNotification);
    setTimeout(() => {
      this.showNotificationAlert = false;
      if (this.notifications) {
        this.notifications.unshift(notification);
      }
    }, NOTIFICATION_TIMEOUT);
  }

  checkRoute() {
    return this.$route.path.includes("/orders");
  }

  handleNewReview(review: ReviewMessage) {
    if (this.reviewsMessages) {
      this.reviewsMessages.unshift(review);
    }
  }

  private playSound(sound) {
    const audio = new Audio(`data:audio/mpeg;base64,${sound}`);
    audio.play();
  }
}
