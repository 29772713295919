interface AppState {
  key: string;
}

const appState: AppState = {
  key: 'value',
};

const getters = {};

// const mutationTypes = {};

const mutations = {};

const actions = {};

export default {
  namespaced: true,
  state: appState,
  getters,
  actions,
  mutations,
};
