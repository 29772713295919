








import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CurrentDate extends Vue {
  @Prop() public prop!: boolean;

  time = new Date();

  tick() {
    this.time = new Date();
  }

  mounted() {
    setInterval(this.tick, 1000);
  }
}
