import VueSocketIOExt from 'vue-socket.io-extended';
import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format';
import VueTruncate from 'vue-truncate-filter';
import Vue from 'vue';

import App from './App.vue';
import store from './store';

import './scss/style.scss';
import './assets/fonts/roboto.css';

import router from './router';

Vue.config.productionTip = false;

Vue.use(VueTruncate);
Vue.use(VueFilterDateFormat, {
  dayOfWeekNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  dayOfWeekNamesShort: ['Su', 'Mo', 'Tu', 'We', 'Tr', 'Fr', 'Sa'],
  monthNames: ['Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря'],
  monthNamesShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
  // Timezone offset, in minutes (0 - UTC, 180 - Russia, undefined - current)
  timezone: 180,
});

const URL = 'https://dashboard.mlkitchen.ai/dashboard';
// @ts-ignore
const socket = io(URL, {
  transports: ['websocket', 'polling'],
  reconnection: true,
  reconnectionAttempts: Infinity,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  randomizationFactor: 0.5,
});

Vue.use(VueSocketIOExt, socket);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
