














import { Component, Prop, Vue } from 'vue-property-decorator';
import RateItem from '@/components/RateItem.vue';
import { MESSAGES_TYPES, NotificationItem } from '@/utils/models';

@Component({
  components: { RateItem },
})
export default class Notifications extends Vue {
  @Prop() public notifications!: NotificationItem[];

  messagesTypes = MESSAGES_TYPES;
}
