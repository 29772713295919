
















import {
  Component, Prop, Vue,
} from 'vue-property-decorator';

@Component
export default class RateItem extends Vue {
  @Prop() public rate!: number | string;
}
